<template>
  <el-dialog :title="title" width="600px" @open="init()" :visible.sync="visible">
    <el-form ref="form" :model="form" label-width="60px" :rules="rules" size="small">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="年份" prop="year">
        <el-select v-model="form.year">
          <el-option v-for="item in years" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="$emit('change', false)">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submit" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "show",
    event: "change"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      form: {
        name: null,
        year: null
      },
      rules: {
        name: [{ required: true, message: '名称必填' }],
        year: [{ required: true, message: '年份必填' }]
      },
      years: []
    }
  },
  computed: {
    title() {
      return this.row.id ? '修改' : '新增'
    },
    visible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  created() {
    this.getYearOptions();

  },
  methods: {
    getYearOptions() {
      let year = new Date().getFullYear();
      for (var i = 0; i < 5; i++) {
        this.years.push(year + i)
      }
    },
    init() {
      if (this.$refs.form) this.$refs.form.resetFields();
      if (this.row.id) {
        this.form = { ...this.row }
      } else {
        this.form = {
          name: null,
          year: null
        }
      }

    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let api, msg;
          if (this.row.id) {
            api = '/platform-admin/scheduling-holiday/update';
            msg = '修改成功！'
          } else {
            api = '/platform-admin/scheduling-holiday/save';
            msg = '添加成功！'
          }
           this.loading = true;
          this.$post(api, this.form).then(res => {
            if (res.code === 0) {
              this.$message.success(msg);
              this.$emit('submit')
              this.$emit('change', false);
            }
          }).finally(()=>{
              this.loading = false
          })
        }
      });
    }
  }
}
</script>

<style lang='scss' scoped >
</style>
