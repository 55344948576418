<template>

  <div class="wrap">
    <div class="left">
      <el-button class="add" type="primary" icon="el-icon-circle-plus" size="small" @click="type={};typeShow = true">新增</el-button>

      <div class="typeList">
        <el-scrollbar>
          <ul class="type">
            <li v-for="item in typeList" :key="item.id" @click="typeClick(item)" :class="{'active':item.id===activeType.id}">
              <div class="name">{{item.name}}

              </div>
              <div class="icon">
                <img src="@/assets/img/icon/icon_edit.png" alt="" @click.stop="editType(item)">
                <img src="@/assets/img/icon/icon_delete.png" alt="" @click.stop="removeType(item)">
              </div>
            </li>
          </ul>
        </el-scrollbar>

      </div>
    </div>
    <div class="right">
      <jf-layout>
        <template slot="head">
          <el-form :inline="true" size="small">

            <el-form-item>
              <el-button class="add" type="primary" icon="el-icon-circle-plus" size="small" @click="add">新增配置</el-button>
            </el-form-item>

          </el-form>
        </template>
        <jf-tip slot="tip" :total="total" :count="selectList.length">
          <el-button :disabled="!selectList.length" size="mini" type="danger" icon="el-icon-delete" @click="remove()">删除</el-button>
        </jf-tip>
        <el-table border slot="body" v-loading="loading" :data="list" @selection-change="selectionChange">
          <el-table-column prop="name" label="节假日名称" />
          <el-table-column label="放假时间">
            <span slot-scope="{row}">
              总共{{row.holidayRestDate?row.holidayRestDate.length:0}}天：
              {{row.holidayRestDate?row.holidayRestDate.join("、"):''}}
            </span>
          </el-table-column>
          <el-table-column label="上班时间">
            <span slot-scope="{row}">
              总共{{row.holidayWorkDate?row.holidayWorkDate.length:0}}天：
              {{row.holidayWorkDate?row.holidayWorkDate.join("、"):''}}
            </span>
          </el-table-column>
          <el-table-column prop="remarks" label="备注" />
          <el-table-column label="操作" width="200">
            <template slot-scope="{row}">
              <el-button size="mini" icon="el-icon-edit" @click="edit(row)">编辑</el-button>
              <el-button size="mini" icon="el-icon-delete" @click="remove(row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <jf-page slot="foot" :total="total" :page.sync="params.page" :limit.sync="params.limit" @change="getList" />
      </jf-layout>
      <Type v-model='typeShow' :row="type" @submit="getType" />
      <Form v-model='formShow' :type="activeType" :row="row" @add="search" @edit="getList" />
    </div>
  </div>
</template>
<script>

import Type from "./type.vue"
import Form from "./form.vue"
export default {
  components: {
    Type, Form
  },
  data() {
    return {
      formShow: false,
      typeShow: false,
      selectList: [],
      total: 0,
      list: [],
      typeList: [],
      params: {
        page: 1,
        limit: 10,
      },
      row: {}, //当前选择编辑删除的节假日
      activeType: {}, //当前（激活）查询的类型
      type: {}, // 
      loading: false,
      row: {}
    };
  },
  created() {
    this.getType();
  },

  methods: {
    add() {
      if (!this.activeType.id) {
        this.$message.warning('请先选择类型！')
        return
      };
      this.row = {};
      this.formShow = true;
    },
    getType() {
      this.$get('/platform-admin/scheduling-holiday/list').then(res => {
        if (res.code === 0) {
          this.typeList = res.data;
          /* 判断是否是第一次进页面 */
          if (!this.activeType.id && this.typeList[0]) {
            this.activeType = this.typeList[0];
            this.search();
          }
        }
      })
    },
    getList() {
      this.loading = true;
      this.$post('/platform-admin/scheduling-holiday-details/listPage', { ...this.params, schedulingHolidayId: this.activeType.id }).then(res => {
        if (res.code === 0) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    search() {
      this.params.page = 1;
      this.getList();
    },
    edit(row) {
      this.row = row;
      this.formShow = true
    },
    remove(id) {
      this.$confirm("确定删除此数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post('/platform-admin/scheduling-holiday-details/delete', [id]).then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功！');
            this.getList();
          }
        })
      }).catch(() => { })

    },
    editType(item) {
      this.type = JSON.parse(JSON.stringify(item));
      this.typeShow = true
    },
    removeType(item) {
      this.$confirm("确定删除此数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post('/platform-admin/scheduling-holiday/delete', [item.id]).then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功！');
            this.getType()
          }
        })
      }).catch(() => { })

    },
    typeClick(item) {
      this.activeType = item;
      this.search();
    },
    selectionChange(list) {
      this.selectList = list;
    }
  }
};
</script>
<style lang="scss" scoped >
// 左侧类型选择
.wrap {
  min-height: calc(100vh - 108px);
  display: flex;
  margin: -20px;
  overflow: hidden;
  .left {
    width: 15%;
    min-width: 250px;
    padding: 20px;
    border-right: 1px solid #e2e7eb;
    box-shadow: 2px 1px 10px 0px rgba(51, 161, 239, 0.18);
    .add {
      margin-bottom: 15px;
    }
    .add + .add {
      margin-left: 10px;
    }
  }
  .right {
    width: 85%;
    padding: 20px;
    overflow: hidden;
    .search-one {
      float: right;
      margin-right: 0;
      /deep/ .el-input-group__append,
      .el-input-group__prepend {
        color: #ffffff !important;
        background-color: #34a1f0 !important;
        border-color: #34a1f0 !important;
      }
      .el-input-group__append .el-button {
        color: #fff;
      }
    }
  }
}
.typeList {
  margin: 0px -20px 0px -20px;
  height: calc(100% - 48px);
  .type-title {
    padding: 0px 30px;
    height: 40px;
    margin-bottom: 15px;
    line-height: 40px;
    opacity: 1;
    background: #eaf6ff;
    color: #34a1f0;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    i {
      font-size: 18px;
      margin-right: 5px;
      vertical-align: middle;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .el-scrollbar {
    height: calc(100% - 65px);
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  ul.type {
    padding: 0 0 0 20px;
    > li {
      display: flex;
      height: 36px;
      line-height: 36px;
      padding-left: 12px;
      padding-right: 12px;
      border-bottom: 1px solid #eee;
      border-left: 2px solid transparent;
      &:hover {
        cursor: pointer;
      }
      &.active {
        background: rgb(223, 239, 250);
        border-left: 2px solid #409eff;
        .name {
          color: #409eff;
        }
      }
      .name {
        flex: 1;
        color: #676767;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon {
        width: 60px;
        min-width: 60px;
        text-align: right;
        img:hover {
          opacity: 0.7;
        }
        img:nth-child(2) {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
